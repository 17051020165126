import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';

export interface WithRouter {
  navigate: NavigateFunction,
  location: Location
}

const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    return (
      <Component  
        navigate={navigate} 
        location={location}     
        {...props}
        />
    );
  };
  
  return Wrapper;
};

export default withRouter;