export interface IAuthConfig {
    loginEndpoint: string,
    logoutEndpoint: string,
    refreshEndpoint: string,
    resetPasswordEndpoint: string,
    confirmPasswordEndpoint: string,
    refreshThresholdMin: number,
    scopes: Array<string>,
    switchTenantEndpoint: string,
}

export default {
    loginEndpoint: 'auth/login',
    logoutEndpoint: 'auth/logout',
    refreshEndpoint: 'auth/refresh',
    resetPasswordEndpoint: 'auth/resetpassword',
    confirmPasswordEndpoint: 'auth/confirmpassword',
    refreshThresholdMin: 150, // token lifetime is 180min so refresh each token each 30 min i.e. 150 min before expirison
    scopes: [],
    switchTenantEndpoint: 'auth/switchTenant'
} as IAuthConfig;
