import {NetworkCallback} from '../types';

const handle = (callback: NetworkCallback, online: boolean) => {  
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(() => callback(online));
  } else {
    setTimeout(() => callback(online), 0);
  }
};

const detectNetwork = (callback: NetworkCallback) => {

  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('online', () => handle(callback, true));
    window.addEventListener('offline', () => handle(callback, false));
    handle(callback, window.navigator.onLine);
  }
};

export default detectNetwork;
