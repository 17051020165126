import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.AuditRuleState = {
    records: [],
    loading: 'idle',
    error: null
}


export const saveAuditRule = createAsyncThunk(
    'auditRules/saveAuditRule',    
    async (data: dom.SaveAuditRule) => {
        try {           

            const response = await apiService.post<dom.SaveAuditRule>('/auditrule', data) as AxiosResponse<dom.AuditRule>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving audit rule');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)


export const loadAuditRules = createAsyncThunk(
    'auditRules/loadAuditRules',
    async () => {
        const response = await apiService.get('/auditrule') as AxiosResponse<dom.AuditRule[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading report resources');
        }
    }
)


export const deleteAuditRule = createAsyncThunk(
    'auditRules/deleteAuditRule',
    async (id: string) => {
        const response = await apiService.delete<any>('/auditrule/' + id, undefined) as AxiosResponse<any>
        if (!response) {
            throw new Error('unknown error deleting audit rule');
        }
    }
)

export const confirmAuditRulesUpload = createAsyncThunk(
    'auditRules/confirmAuditRulesUpload',    
    async (data: dom.AuditRulesUploadInfo) => {
        try {
            const response = await apiService.post<dom.AuditRulesUploadInfo>('/auditrule/confirmupload', data) as AxiosResponse<dom.AuditRule[]>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error uploading audit rules');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)



const sliceReducer = createSlice({
    name: 'auditRules',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // save
        builder.addCase(saveAuditRule.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.map(t => t.id === action.payload.id ? action.payload : t),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveAuditRule.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadAuditRules.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadAuditRules.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // delete
        builder.addCase(deleteAuditRule.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.filter(t => t.id !== action.meta.arg),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteAuditRule.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // confirm upload
        builder.addCase(confirmAuditRulesUpload.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(r => !action.payload.some(rr => rr.id === r.id)), ...action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(confirmAuditRulesUpload.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 
        
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
