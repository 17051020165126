import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'


dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export default dayjs;
