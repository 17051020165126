import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.RegulatoryReportState = {
    records: [],
    loading: 'idle',
    error: null
}


export const saveRegulatoryReport = createAsyncThunk(
    'regulatoryReports/saveRegulatoryReport',
    // if you type your function argument here
    async (data: dom.SaveReportRequest) => {
        try {

            if (data.files) {
                for (let file of data.files) {
                    await apiService.uploadFile(file.filePath, file.blob);                    
                }
            }
            data.files = undefined;
          
            const response = await apiService.post<dom.SaveReportRequest>('/regulatoryreport', data) as AxiosResponse<dom.RegulatoryReport>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving regulatory report');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const getRegulatoryReport = createAsyncThunk(
    'regulatoryReports/getRegulatoryReport',
    async (id: string) => {
        const response = await apiService.get(`/regulatoryreport/${id}`) as AxiosResponse<dom.RegulatoryReport>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error get regulatory report');
        }
    }
)

export const submitRegulatoryReport = createAsyncThunk(
    'regulatoryReports/submitRegulatoryReport',    
    async (data: dom.SaveReportRequest) => {
        try {
          
            const response = await apiService.post<dom.SaveReportRequest>('/regulatoryreport/submit', data) as AxiosResponse<dom.RegulatoryReport>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error submitting regulatory report');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const disputeRegulatoryReport = createAsyncThunk(
    'regulatoryReports/disputeRegulatoryReport',    
    async (data: dom.SaveReportRequest) => {
        try {
          
            const response = await apiService.post<dom.SaveReportRequest>('/regulatoryreport/dispute', data) as AxiosResponse<dom.RegulatoryReport>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error disputing regulatory report');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const acceptRegulatoryReport = createAsyncThunk(
    'regulatoryReports/acceptRegulatoryReport',    
    async (data: dom.SaveReportRequest) => {
        try {
          
            const response = await apiService.post<dom.SaveReportRequest>('/regulatoryreport/accept', data) as AxiosResponse<dom.RegulatoryReport>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error accepting regulatory report');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)


export const discardRegulatoryReport = createAsyncThunk(
    'regulatoryReports/discardRegulatoryReport',    
    async (data: dom.SaveReportRequest) => {
        try {
          
            const response = await apiService.post<dom.SaveReportRequest>('/regulatoryreport/discard', data) as AxiosResponse<dom.RegulatoryReport>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error discarding regulatory report');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)



export const loadRegulatoryReports = createAsyncThunk(
    'regulatoryReports/loadRegulatoryReports',
    async () => {
        const response = await apiService.get('/regulatoryreport') as AxiosResponse<dom.RegulatoryReport[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading regulatory reports');
        }
    }
)

const sliceReducer = createSlice({
    name: 'regulatoryReports',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // save
        builder.addCase(saveRegulatoryReport.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveRegulatoryReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadRegulatoryReports.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadRegulatoryReports.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 


        // get
        builder.addCase(getRegulatoryReport.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getRegulatoryReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })


        // submit
        builder.addCase(submitRegulatoryReport.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(submitRegulatoryReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
        
        // dispute
        builder.addCase(disputeRegulatoryReport.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(disputeRegulatoryReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // accept
        builder.addCase(acceptRegulatoryReport.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(acceptRegulatoryReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // discard
        builder.addCase(discardRegulatoryReport.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(discardRegulatoryReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
