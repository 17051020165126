import React, { Component, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './layout/Loader'
import ScrollToTop from './layout/ScrollToTop';
import routes, {RouteDes} from "../route";
import { authProvider, AuthContext, PrivateRouteOutlet } from "../common/auth";
import withRouter from "../common/hoc/withRouter";



const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component<any> {

    // constructor (props: any) {
    //     super(props);
    // }

    render() {

        const {pathname} = this.props.location;
        const returnUrl = this.props.location?.state?.returnUrl;

        const createRoutes = (routeDes: RouteDes[]) => {

            return routeDes.map((route, index) => {
                return !!route.component ? (
                    <Route                        
                        key={index}
                        path={route.path}
                        element={<route.component returnUrl={returnUrl || pathname} />} >
                            <>{!!route.children ? createRoutes(route.children) : null}</>
                    </Route>
                ) : (null);
              });
        }
        
        const menu = createRoutes(routes);

        const isAuthenticated = authProvider.isAuthenticated()
        const AccountInfo = authProvider.getAccountInfo()

        return (
            <AuthContext.Provider value={{
                isAuthenticated: isAuthenticated,
                account: AccountInfo
            }}>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            {menu}
                            <Route path="*" element={<PrivateRouteOutlet returnUrl={returnUrl || pathname} />}>
                                <Route path="*" element={<AdminLayout />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </ScrollToTop>
            </AuthContext.Provider>
        );
    }
}

export default withRouter(App);
