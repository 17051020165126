import { OfflineAction, ImageData } from '../types';
import apiService from "../../api.service";
import ImageUtils from "../../../utils/ImageUtils";

export class NetworkError extends Error {
  public readonly name: string = 'NetworkError';

  constructor(public response?: string | object, public status?: number, public innerError?: Error) {
    super(innerError?.message);
  }
}

export const tryParseJSON = (json: string): object | undefined => {
  if (!json) {
    return undefined;
  }
  try {
    return JSON.parse(json);
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`);
  }
};


export const getHeaders = (headers: {
  [key: string]: string
}): { [key: string]: string } => {
  const {
    'Content-Type': contentTypeCapitalized,
    'content-type': contentTypeLowerCase,
    ...restOfHeaders
  } = headers || {};
  const contentType =
    contentTypeCapitalized || contentTypeLowerCase || 'application/json';
  return { ...restOfHeaders, 'content-type': contentType };
};

export const getFormData = (object: {}) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    Object.keys(object[key as keyof object]).forEach(innerObj => {
      const newObj = object[key as keyof object][innerObj];
      formData.append(newObj[0], newObj[1]);
    });
  });

  return formData;
};

const getImageFormData = (photo: ImageData) => {        
  
  const blobData = ImageUtils.dataUriToBlob(photo.dataUri!);
  const formData = new FormData()
  formData.append(
      'Image',
      blobData,
      photo.fileName
  );

  if (!!photo.resize){  
    formData.append(
        'Resize',
        photo.resize
    );
  }

  return formData;
}

const offlineEffect = (effect: any, _action: OfflineAction): Promise<any> => {
  const { url, json, method, ...options } = effect;
  const headers = getHeaders(options.headers);
  const photosUrl = 'files/image';

  let data: any = json;

  try {
    if (!(options.body instanceof FormData) &&
      Object.prototype.hasOwnProperty.call(headers, 'content-type') &&
      headers['content-type'].toLowerCase().includes('multipart/form-data')
    ) {    
      data = getFormData(options.body);
    }

    if(url === photosUrl) {
      data = getImageFormData(data as ImageData);
    }
  } catch (error) {
    return new Promise<any>((resolve, reject) => reject(reject))   
  }

  return apiService.request<any,any>(url, method, data, {headers})
    .then((res: any) => res.data)
    .catch(error => {      
      throw new NetworkError(error.response, error.response?.status, error)
    })
};

export default offlineEffect;
