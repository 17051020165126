import type { OfflineAction } from '../types';
import { NetworkError } from './effect';

const offlineDiscard = (
  error: NetworkError,
  action: OfflineAction,
  _retries: number = 0 
): boolean => {
  
  // Network error
  if(!error.response && /Network.+/i.test(error.innerError?.message ?? "")) {
    return false;
  }

  // discard http 4xx errors
  const status = error.status ?? 0;
  return status >= 400 && status < 500;
};

export default offlineDiscard;
