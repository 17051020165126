import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.DataEntityState = {
    searchResults: {},
    loading: 'idle',
    error: null
}

export const getSearchResult = createAsyncThunk(
    'dataEntities/getSearchResult',
    // if you type your function argument here
    async (id: string) => {
        try {
           
            const response = await apiService.get(`/gateway/entitiesresult/${id}`) as AxiosResponse<dom.DataEntity[]>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error loading search result');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)


const sliceReducer = createSlice({
    name: 'dataEntities',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // get
        builder.addCase(getSearchResult.fulfilled, (state, action) => {
            return {
                ...state,
                // For now keep just last search result
                searchResults: {[action.meta.arg]: action.payload},
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getSearchResult.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
