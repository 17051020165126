import {OfflineAction, OfflineState, ResultAction, Enqueue, Dequeue, Peek} from '../types'

const enqueue: Enqueue = (
  array: Array<OfflineAction>,
  item: OfflineAction,
  context: { offline: OfflineState }
) => {
  return [...array, item];
}

const dequeue: Dequeue = (array: Array<OfflineAction>,
  item: ResultAction,
  context: { offline: OfflineState }) => {
  const [, ...rest] = array;
  return rest;
}

const peek: Peek = (array: Array<OfflineAction>,
  item: OfflineAction,
  context: { offline: OfflineState }) => {
  return array[0];
}

const queue = {
  enqueue,
  dequeue,
  peek
};

export default queue;
