import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { authProvider } from './AuthProvider';


const PrivateRouteComp = (props: any) => {

    const isAuthenticated = authProvider.isAuthenticated()

    return (
        isAuthenticated
            ? (<Outlet />)
            : (<Navigate to={'/login'} state={{returnUrl: props.returnUrl}} />)
    )
}

export const PrivateRouteOutlet = PrivateRouteComp
