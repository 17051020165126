import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import rootReducer from './reducers'
import * as storage from '../common/cache/engine'
import createEngine from './localStorage'
import { TEMPLATE_ACTIONS } from './actions/template'
import { createOffline } from '../common/offline';
import defaultConfigOffline from '../common/offline/defaults';

const middleware = [
    ...getDefaultMiddleware()
]

if (process.env.NODE_ENV === 'development') {
    middleware.push(require('redux-logger').default)
}

const configOffline = {
   ...defaultConfigOffline,
};

const offline = createOffline(configOffline);

const offlineReducer = offline.enhanceReducer(rootReducer)
const cacheReducer = storage.reducer(offlineReducer)

const cacheEngine = createEngine('reg-report-data')
const actionBlacklist = [...TEMPLATE_ACTIONS, '/pending']
const cacheMiddleware = storage.createMiddleware(cacheEngine, actionBlacklist)
middleware.push(cacheMiddleware)
middleware.push(offline.middleware)
const load = storage.createLoader(cacheEngine)

export const loadCache = () => load(store)
export const clearCache = () => cacheEngine.save({})

const store = configureStore({
    reducer: cacheReducer,
    middleware,
    enhancers: [offline.enhanceStore as any]
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store

//https://redux-toolkit.js.org/usage/usage-with-typescript/
