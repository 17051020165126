import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.ReportResourceState = {
    records: [],
    loading: 'idle',
    error: null
}


export const saveResource = createAsyncThunk(
    'reportResources/saveResource',
    // if you type your function argument here
    async (data: dom.SaveResourceRequest) => {
        try {            
            if(data.file) {
                await apiService.uploadFile(data.file.filePath, data.file.blob);
                data.file = undefined;
            }

            const response = await apiService.post<dom.SaveResourceRequest>('/resource', data) as AxiosResponse<dom.ReportResource>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving report resource');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const uploadResources = createAsyncThunk(
    'reportResources/uploadResources',
    async (files: File[]) => {
        try {
                        
            const formData = new FormData()
            for(let i = 0; i < files.length; i++) {
                formData.append(
                    'File' + i,
                    files[i],
                    files[i].name
                );
            }

            const response =await apiService.post('resource/upload', formData) as AxiosResponse<dom.ReportResource[]>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving report resource');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)


export const loadResources = createAsyncThunk(
    'reportResources/loadResources',
    async () => {
        const response = await apiService.get('/resource') as AxiosResponse<dom.ReportResource[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading report resources');
        }
    }
)

export const deleteResource = createAsyncThunk(
    'reportResources/deleteResource',
    async (id: string) => {
        const response = await apiService.delete<any>('/resource/' + id, undefined) as AxiosResponse<any>
        if (!response) {
            throw new Error('unknown error deleting report resource');
        }
    }
)


const sliceReducer = createSlice({
    name: 'reportResources',
    initialState,
    reducers: {
    },
    extraReducers: builder => {


        // save
        builder.addCase(saveResource.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveResource.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadResources.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadResources.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // upload
        builder.addCase(uploadResources.fulfilled, (state, action) => {
            
            return {
                ...state,
                records: [...state.records.filter(r => !action.payload.some(rr => rr.id === r.id)), ...action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(uploadResources.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 


        // delete
        builder.addCase(deleteResource.fulfilled, (state, action) => {
            
            return {
                ...state,
                records: [...state.records.filter(r => r.id !== action.meta.arg)],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteResource.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 
        
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
