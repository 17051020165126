import React from 'react';
import ComponentLoader from './app/components/ComponentLoader'

const SignIn = React.lazy(() => ComponentLoader(() => import('./app/pages/Login/SignIn'))  as any);
const ResetPassword = React.lazy(() => ComponentLoader(() => import('./app/pages/Login/ResetPassword')) as any);
const ResetPasswordConfirm = React.lazy(() => ComponentLoader(() => import('./app/pages/Login/ResetPasswordConfirm')) as any);

export interface RouteDes {
    path: string,
    name: string,
    component: any,
    appMode?: boolean,
    role?: string,
    children?: RouteDes[]
}

const route: RouteDes[] = [
    { path: '/login', name: 'Login', component: SignIn },
    { path: '/reset-password', name: 'Reset Password', component: ResetPassword },
    { path: '/confirm-password/:hash', name: 'Confirm Reset Password', component: ResetPasswordConfirm },
    { path: '/confirm-password/', name: 'Confirm Reset Password', component: ResetPasswordConfirm },
];

export default route;
