import store, { loadCache, clearCache } from "../../store/store"
import { loadTenants, loadSystemUsers } from "../../store/reducers/domain/tenants"
import { loadResources } from "../../store/reducers/domain/reportResources"
import { loadAuditRules } from "../../store/reducers/domain/auditRules"
import { loadDataGateways } from "../../store/reducers/domain/dataGateways"
import { loadReportDefinitions } from "../../store/reducers/domain/regReportDefinitions"
import { loadRegulatoryReports } from "../../store/reducers/domain/regulatoryReports"
import { loadAsyncTasks } from "../../store/reducers/domain/asyncTasks"
import { loadApplicationUpdates } from "../../store/reducers/domain/applicationUpdates"
import dayjs from "../dayjs"
import { authProvider } from "../auth/AuthProvider"

class AppCache
{
    constructor() {
        this.loadInitialStateFromCache()
    }

    clearCache(): Promise<any> {
        localStorage.removeItem('reg-report-data') //Remove old cache of data
        return clearCache()
    }

    loadInitialStateFromAPI(): Promise<any> {
        //let dispatch: AppDispatch = useAppDispatch.dispatch
        //Loads the state from the API into the redux cache
        let promise = new Promise((resolve) => {
            resolve(undefined)
        })
        
        promise = promise.then(() => store.dispatch(loadTenants()))
        promise = promise.then(() => store.dispatch(loadSystemUsers()))
        promise = promise.then(() => store.dispatch(loadResources()))
        promise = promise.then(() => store.dispatch(loadAuditRules()))
        promise = promise.then(() => store.dispatch(loadDataGateways()))
        promise = promise.then(() => store.dispatch(loadReportDefinitions()))
        promise = promise.then(() => store.dispatch(loadRegulatoryReports()))
        promise = promise.then(() => {
            if (authProvider.hasRole(authProvider.Roles.SaaSAdministrator)) {
                store.dispatch(loadApplicationUpdates())
            }

            return new Promise((resolve) => {
                resolve(undefined)
            });
        })
        promise = promise.then(() => {
            // Convert local time as utc due to it is converted to uts on deserialize
            const start = dayjs.utc().startOf('day');
            return store.dispatch(loadAsyncTasks({
                startDate: start.toJSON()
            }));
        });

        return promise
    }

    loadInitialStateFromCache(): Promise<any> {
        console.log("Loading initial state from cache")
        return loadCache()
    }
}

const appCache = new AppCache()
export default appCache
