import { combineReducers } from '@reduxjs/toolkit'

import template from "./template"
import tenants from "./domain/tenants"
import reportResources from "./domain/reportResources"
import auditRules from "./domain/auditRules"
import dataGateways from "./domain/dataGateways"
import asyncTasks from "./domain/asyncTasks"
import regReportDefinitions from "./domain/regReportDefinitions"
import regulatoryReports from "./domain/regulatoryReports"
import dataEntities from "./domain/dataEntities"
import applicationUpdates from "./domain/applicationUpdates"

const appReducer = combineReducers({
    template, 
    tenants, 
    reportResources,
    auditRules,
    dataGateways,
    asyncTasks,
    regReportDefinitions,
    regulatoryReports,
    dataEntities,
    applicationUpdates
})

const rootReducer = (state: any, action: any) => {
    // when a redux save is sent with an empty payload, reset the redux store to initial
    if (action?.type === 'REDUX_STORAGE_SAVE' && Object.keys(action?.payload ?? {}).length === 0) {
        state = undefined;
    }
    return appReducer(state, action);
};


export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
