import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
//import Backend from 'i18next-http-backend';
import dayjs from "../common/dayjs";
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/en-ca'
import 'dayjs/locale/es-us'


import setYupLocale from './yupLocale'
import languageEN from './en/common.json'
import languageENCustom from './en/custom_translation.json'
import languageFR from './fr/common.json'
import languageFRCustom from './fr/custom_translation.json'
import languageES from './es/common.json'
import languageESCustom from './es/custom_translation.json'

const allEn = {...languageEN, ...languageENCustom};
const allFr = {...languageFR, ...languageFRCustom};
const allSp = { ...languageES, ...languageESCustom };

setYupLocale();

const lookupLocalStorage = 'RegulatoryReport_i18nextLng';
if (!localStorage.getItem(lookupLocalStorage)) {

    let initialLang = 'en';
    if (navigator.language && navigator.language.startsWith('fr'))
        initialLang = 'fr';
    if (navigator.language && navigator.language.startsWith('es'))
        initialLang = 'es';

    localStorage.setItem(lookupLocalStorage, initialLang);
}

let settings: InitOptions = {
    // There is some problem with dynamic loading by backend so load all statically
    resources: {
        en: {
            translations: allEn
        },
        fr: {
            translations: allFr
        },
        es: {
            translations: allSp
        }
    },
    load: 'currentOnly',
    /* default language when load the website in browser - dont use with languagedetector */
    //lng: "en",
    /* When react i18next not finding any language to as default in browser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        useSuspense: true,
        bindI18n: 'languageChanged loaded',
        //bindStore: 'added removed',
        nsMode: 'default'
    },
    backend: {
        loadPath: '.locales/{{lng}}.json',
    },
    detection: {
        lookupLocalStorage: lookupLocalStorage,
    }
}

if (process.env.NODE_ENV === 'development') {
    //settings['debug'] = true
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    //.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(settings);

const CustomLanguageHeader = "UI-Language";

export const getLanguageHeader = () : any => {
    return {
        [CustomLanguageHeader]: i18n.language
    };
}

function setMomentLocale(lang: string) {    
    if(lang === 'es') {
        dayjs.locale('es-us'); 
    } else {
        dayjs.locale(lang + '-ca'); 
    }
}

setMomentLocale(i18n.language); 

export { setMomentLocale };
export default i18n;
