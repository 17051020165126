import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.ApplicationUpdateState = {
    records: [],    
    loading: 'idle',
    error: null
}


export const saveApplicationUpdate = createAsyncThunk(
    'applicationUpdates/saveApplicationUpdate',
    async (data: dom.SaveApplicationUpdate) => {
        try {            
            if (data.files) {
                for (let file of data.files) {
                    await apiService.uploadFile(file.filePath, file.blob);                    
                }
            }
            data.files = undefined;
         
            const response = await apiService.post<dom.SaveApplicationUpdate>('/appupdates', data) as AxiosResponse<dom.ApplicationUpdate>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving application update');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const uploadApplicationVersion = createAsyncThunk(
    'applicationUpdates/uploadApplicationVersion',
    async (data: {id: string, uploadFile?: File}) => {
        try {
            const formData = new FormData()
            if (data.uploadFile) {
                formData.append(
                    'File',
                    data.uploadFile,
                    data.uploadFile.name
                );
                data.uploadFile = undefined;
            }

            const response = await apiService.post(`appupdates/${data.id}/upload`, formData) as AxiosResponse<dom.ApplicationUpdateVersion>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving application update');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const resetUploadingVersion = createAsyncThunk(
    'applicationUpdates/resetUploadingVersion',
    async () => {
        return;
    }
)

export const deleteApplicationUpdate = createAsyncThunk(
    'applicationUpdates/deleteApplicationUpdate',
    async (id: string) => {
        const response = await apiService.delete<any>('/appupdates/' + id, undefined) as AxiosResponse<any>
        if (!response) {
            throw new Error('unknown error deleting application update');
        }
    }
)

export const deleteUpdateVersion = createAsyncThunk(
    'applicationUpdates/deleteUpdateVersion',
    async (data: {
        id: string,
        versionId: string
    }) => {
        const response = await apiService.delete<any>(`/appupdates/${data.id}/version/${data.versionId}`, undefined) as AxiosResponse<dom.ApplicationUpdate>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error deleting application update');
        }
    }
)

export const loadApplicationUpdates = createAsyncThunk(
    'applicationUpdates/loadApplicationUpdates',
    async () => {
        const response = await apiService.get('/appupdates') as AxiosResponse<dom.ApplicationUpdate[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading application updates'); 
        }
    }
)



const sliceReducer = createSlice({
    name: 'applicationUpdates',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // save
        builder.addCase(saveApplicationUpdate.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                uploadingVersion: undefined,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveApplicationUpdate.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // upload
        builder.addCase(uploadApplicationVersion.fulfilled, (state, action) => {
            return {
                ...state,
                uploadingVersion: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(uploadApplicationVersion.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // reset uploading version
        builder.addCase(resetUploadingVersion.fulfilled, (state, action) => {
            return {
                ...state,
                uploadingVersion: undefined,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(resetUploadingVersion.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadApplicationUpdates.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadApplicationUpdates.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // delete
        builder.addCase(deleteApplicationUpdate.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.filter(t => t.id !== action.meta.arg),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteApplicationUpdate.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // delete version
        builder.addCase(deleteUpdateVersion.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                uploadingVersion: undefined,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteUpdateVersion.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
        
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
