import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.AsyncTaskState = {
    records: [],
    loading: 'idle',
    error: null
}

export const getAsyncTask = createAsyncThunk(
    'asyncTasks/getAsyncTask',
    // if you type your function argument here
    async (id: string) => {
        try {           
            const response = await apiService.get(`/asynctask/${id}`) as AxiosResponse<dom.AsyncTaskItem>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error loading async task');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const loadAsyncTasks = createAsyncThunk(
    'asyncTasks/loadAsyncTasks',
    async (data: dom.LoadAsyncTasksRequest) => {
        const response = await apiService.post<dom.LoadAsyncTasksRequest>('/asynctask', data) as AxiosResponse<dom.AsyncTaskItem[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading async tasks');
        }
    }
)

export const postAuditJob = createAsyncThunk(
    'asyncTasks/postAuditJob',    
    async (data: dom.PostReportJobRequest) => {
        try {           

            const response = await apiService.post<dom.PostReportJobRequest>('/gateway/postauditjob', data) as AxiosResponse<dom.AsyncTaskItem>            
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving posting audit job');
            }
        } catch (e) {            
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const postReportJob = createAsyncThunk(
    'asyncTasks/postReportJob',    
    async (data: dom.PostReportJobRequest) => {
        try {           

            const response = await apiService.post<dom.PostReportJobRequest>('/gateway/postreportjob', data) as AxiosResponse<dom.AsyncTaskItem>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error saving posting report job');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const postSearchEntities = createAsyncThunk(
    'asyncTasks/postSearchEntities',    
    async (data: dom.PostSearchEntitiesRequest) => {
        try {           

            const response = await apiService.post<dom.PostSearchEntitiesRequest>('/gateway/searchentities', data) as AxiosResponse<dom.AsyncTaskItem>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error posting search entities job');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const postGatewayLogsJob = createAsyncThunk(
    'asyncTasks/postGatewayLogsJob',    
    async (data: dom.PostDataGatewayJobRequest) => {
        try { 
            const response = await apiService.post<dom.PostDataGatewayJobRequest>('/gateway/postgatewaylogsjob', data) as AxiosResponse<dom.AsyncTaskItem>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error posting gateway logs job');
            }
        } catch (e) {
            const error = e as any;
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

const sliceReducer = createSlice({
    name: 'asyncTasks',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // get
        builder.addCase(getAsyncTask.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getAsyncTask.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadAsyncTasks.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadAsyncTasks.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // audit job
        builder.addCase(postAuditJob.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(postAuditJob.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // report job
        builder.addCase(postReportJob.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(postReportJob.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // search entities
        builder.addCase(postSearchEntities.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(postSearchEntities.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // post gateway logs job
        builder.addCase(postGatewayLogsJob.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records.filter(t => t.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(postGatewayLogsJob.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
