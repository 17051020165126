import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";
import { DeleteGatewayRequest } from '../../models/domain';

const initialState: dom.DataGatewayState = {
    records: [],
    loading: 'idle',
    error: null
}



export const loadDataGateways = createAsyncThunk(
    'dataGateways/loadDataGateways',
    async () => {
        const response = await apiService.get('/gateway') as AxiosResponse<dom.DataGateway[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading data gateways');
        }
    }
)


export const deleteDataGateway = createAsyncThunk(
    'dataGateways/deleteDataGateway',
    async (request: DeleteGatewayRequest) => {
        const response = await apiService.delete<any>('/gateway', request) as AxiosResponse<any>
        if (!response) {
            throw new Error('unknown error deleting data gateway');
        }
    }
)



const sliceReducer = createSlice({
    name: 'dataGateways',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // load
        builder.addCase(loadDataGateways.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadDataGateways.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 

        // delete
        builder.addCase(deleteDataGateway.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.filter(t => t.name !== action.meta.arg.name),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteDataGateway.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }) 
        
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
