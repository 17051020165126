import { load as actionLoad } from './actions';

const createLoader = (engine: any) => (store: any) => {
    const dispatchLoad = (state: any) => store.dispatch(actionLoad(state));
    return engine.load().then((newState: any) => {
        dispatchLoad(newState);
        return newState;
    });
};

export default createLoader;
