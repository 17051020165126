import axios from "axios";

import { authProvider } from "./auth"
import tracing from "./tracingContext";
import log from "./logging";
import {getLanguageHeader} from "../locales/i18n";
import ImageUtils from "../utils/ImageUtils";
import { imageCache } from "./dataCache";


class ApiService
{
    constructor() {
        axios.defaults.baseURL = process.env.REACT_APP_API_URI;
    }

    async getRequestDefConfig() {
        const token = await authProvider.getAccessToken();

        let headers =  {
            ...axios.defaults.headers,
            ...tracing.getTracingHeaders(),
            ...getLanguageHeader()         
        }

        if (token)
            headers = {
                ...headers,
                Authorization: 'Bearer ' + token
            }

        return { headers }
    }

    request<T, K>(resource: string, method: string, data: T | null = null, config: any | null = null) {
        if (!resource.startsWith(process.env.REACT_APP_API_URI) && resource.startsWith('/')) {
            resource = process.env.REACT_APP_API_URI + resource;
        }
        return this.getRequestDefConfig()
            .then(defConfig => {                
                const requestConfig = Object.assign({}, defConfig, config, { data, method, url: resource }, {headers: {...defConfig.headers, ...(config ?? {}).headers}});
                return axios.request<K>(requestConfig)
                .catch(error => {
                    log.error(`ApiService ${error}`);                    
                    if (error.response && error.response.status === 401) {
                        // go to login                    
                        authProvider.logout();
                        return undefined;
                    } else
                        throw error;
                })
            });
    }

    get<R = any>(resource: string) {
        return this.request<any, R>(resource, "get");
    }

    post<T = any,K = any>(resource: string, data: T) {        
        return this.request<T, K>(resource, "post", data);
    }
    
    put<T = any, K = any>(resource: string, data: T) {
        return this.request<T, K>(resource, "put", data);
    }

    delete<T = any, K = any>(resource: string, data: T) {
        return this.request<T, K>(resource, "delete", data);
    }

    async uploadPhoto (photoUri: string, dataUri: string, resize?: string) {
         
        const blobData = ImageUtils.dataUriToBlob(dataUri);
        const formData = new FormData()
        formData.append(
            'Image',
            blobData,
            photoUri
        );

        if(!!resize) {
            formData.append(
                'Resize',
                resize
            );
        }


        await this.post('files/image', formData);
        const request = `files/${photoUri}`;
        await imageCache.add(request,dataUri);
    }

    async uploadFile (filePath: string, blob: Blob) {        
    
        const formData = new FormData()
        formData.append(
            'File',
            blob,
            filePath
        );

        await this.post('files', formData);
    }

    async deleteFile (filePath: string) {        
        await this.delete(`files?filePath=${encodeURIComponent(filePath)}`, {});

        const request = `files/${filePath}`;
        await imageCache.delete(request);
    }

    async downloadFile (filePath: string, childFile?: string) {        
    
        return await apiService.request<any, Blob>(`files?filePath=${encodeURIComponent(filePath)}` 
            + (!!childFile ? `&childFile=${encodeURIComponent(childFile)}` : ''),
            'GET', {}, {responseType: 'blob'})
    }
};

const apiService = new ApiService();
export default apiService;
