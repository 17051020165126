import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import {Button, Card} from 'react-bootstrap';

import i18n from './locales/i18n'
//import HistoryListener from './common/HistoryListener'
import App from './app';

import config from './config';
import log from './common/logging';
import store from './store/store'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import './common/style.scss'

const componentErrorHandler = (error: Error, info: {
  componentStack: string;
}) => {    
  log.error('Error render application', error.message, info.componentStack);
}

function ErrorFallback({error, resetErrorBoundary}: FallbackProps) {   
  return (
      <div className="d-flex justify-content-center align-items-center flex-column" style={{height: '100vh'}}>
          <div className='d-flex flex-column'>
              <Card border="danger" style={{ width: '80vw', fontSize: '1.8rem' }}>
                  <Card.Header >{i18n.t<string>('Something went wrong:')}</Card.Header>
                  <Card.Body style={{ color: 'red' }}>
                      <Card.Title style={{fontSize: '1.2rem'}}>{error.message}</Card.Title>
                      <Card.Text style={{background: '#f0f0f0', padding: '10px' }}>
                          <pre>{error.stack}</pre>
                      </Card.Text>
                  </Card.Body>
              </Card>
              <div className="d-flex justify-content-end align-items-center flex-row">
                <Button onClick={() => window.location.href = window.location.origin} variant="primary" className="align-self-end mx-1">{i18n.t<string>('To Main Page')}</Button>
                <Button onClick={resetErrorBoundary} variant="primary" className="align-self-end  mx-1">{i18n.t<string>('Try again')}</Button>
              </div>
          </div>            
      </div>
  )
}

let app =  (
    <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                {/* <HistoryListener> */}
                    <I18nextProvider i18n={i18n}>
                        <App />
                    </I18nextProvider>
                {/* </HistoryListener> */}
            </BrowserRouter>
    </Provider>
  );

  // Custom error boundary only for production and to see error overlay in development
//if (process.env.NODE_ENV === 'production') {
    app = (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={componentErrorHandler}
          onReset={() => {
          window.location.reload();      
        }}
        >
            {app}
        </ErrorBoundary>
      );
//}

const root = createRoot(document.getElementById('root')!);
root.render(app);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


if (process.env.NODE_ENV !== 'production') {
    // Make sure that we get all error messages properly displayed
    // as long as we are not in production mode
    window.onerror = (message, source, lineno, colno, error) => {

        const ignored: any[] = [];

        if (ignored.some(i => message.toString().includes(i))) {
            // Ignore some error
            return;
        }
        log.error(`Message: ${message}, ${error} at ${source} ${lineno}:${colno}`);
    };
}
